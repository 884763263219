

// import React, { useState, useEffect } from 'react';
// import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
// import Dashboard from './pages/Dashboard';
// import PropertyDetails from './pages/PropertyDetails';
// import ListingsPage from './pages/ListingsPage';
// import UserContext from './UserContext';
// import SettingsPage from './pages/SettingsPage';
// import SignIn from './pages/SignIn';
// import axios from 'axios';
// import { auth, firestore } from './firebase';
// import { doc, getDoc } from 'firebase/firestore';

// const PrivateRoute = ({ element: Element, isAdminOnly, ...rest }) => {
//   const { user, isAdmin , setListings } = React.useContext(UserContext);

//   if (!user) {
//     return <Navigate to="/login" replace />;
//   }

//   if (isAdminOnly && !isAdmin) {
//     return <Navigate to="/" replace />;
//   }

//   return <Element {...rest} />;
// };

// const App = () => {
//   const [user, setUser] = useState(null);
//   const [isSidebarOpen, setIsSidebarOpen] = useState(false);
//   const [isAdmin, setIsAdmin] = useState(false);
//   const [listings, setListings] = useState([]); 
//   const [isLoading, setIsLoading] = useState(true);
  

//   useEffect(() => {
//     const unsubscribe = auth.onAuthStateChanged(async (user) => {
//       if (user) {
//         // User is logged in
//         const userDoc = await getDoc(doc(firestore, 'users', user.uid));
//         if (userDoc.exists()) {
//           const userData = userDoc.data();
//           setUser({
//             id: user.uid,
//             email: user.email,
//             ...userData,
//           });
//           console.log(userData.role);
//           setIsAdmin(userData.role === 'admin');
//           if (listings.length === 0) {  // Only fetch listings if not already fetched
//             fetchListings(user.uid, userData.role === 'admin');
//           }
          
//         } else {
//           setUser(null);
//           setIsAdmin(false);
//         }
//       } else {
//         // User is logged out
//         setUser(null);
//         setIsAdmin(false);
//       }
//       setIsLoading(false);
//     });

//     return () => unsubscribe();
//   }, []);

//   const fetchListings = async (userId, isAdmin) => {
//     console.log(userId)
//     try {
//       const response = await axios.get('http://localhost:3000/api/listings', {
//         params: {
//           userId,
//         },
//       });
//       setListings(response.data);
//     } catch (error) {
//       console.error('Error fetching listings:', error);
//     } finally {
//       setIsLoading(false);
//     }
//   };



//   const handleToggleSidebar = () => {
//     setIsSidebarOpen(!isSidebarOpen);
//   };

//   const handleCloseSidebar = () => {
//     setIsSidebarOpen(false);
//   };

//   if (isLoading) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <UserContext.Provider value={{ user, setUser, isSidebarOpen, handleToggleSidebar, handleCloseSidebar, isAdmin,listings, setListings }}>
//       <Router>
//         <Routes>
//           <Route path="/login" element={user ? <Navigate to="/" replace /> : <SignIn />} />
//           <Route path="/" element={<PrivateRoute element={Dashboard} />} />
//           <Route path="/listings" element={<PrivateRoute element={ListingsPage} />} />
//           <Route path="/settings" element={<PrivateRoute element={SettingsPage} isAdminOnly />} />
//           <Route path="/property/:id" element={<PrivateRoute element={PropertyDetails} isAdminOnly />} />
//         </Routes>
//       </Router>
//     </UserContext.Provider>
//   );
// };

// export default App;

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import PropertyDetails from './pages/PropertyDetails';
import ListingsPage from './pages/ListingsPage';
import UserContext from './UserContext';
import SettingsPage from './pages/SettingsPage';
import SignIn from './pages/SignIn';
import axios from 'axios';
import { auth, firestore } from './firebase';
import { doc, getDoc } from 'firebase/firestore';
import DetailedPropertyPage from './pages/DetailedPropertyPage';
import api from './api';
import DetailedReservationPage from './pages/DetailedReservationPage';
import LoadingScreen from './components/LoadingScreen';
import NotFoundPage from './NotFoundPage';
import AccountantPage from './pages/AccountantPage';
import AllReservations from './pages/AllReservations';




const PrivateRoute = ({ element: Element, isAdminOnly, ...rest }) => {
  const { user, isAdmin } = React.useContext(UserContext);

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  if (isAdminOnly && !isAdmin) {
    return <Navigate to="/" replace />;
  }

  return <Element {...rest} />;
};

const App = () => {
  const [user, setUser] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [listings, setListings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        // User is logged in
        const userDoc = await getDoc(doc(firestore, 'users', user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUser({
            id: user.uid,
            email: user.email,
            ...userData,
          });
          console.log(userData.role);
          setIsAdmin(userData.role === 'admin');
          const listingsFetched = localStorage.getItem('listingsFetched');
          
          fetchListings(user.uid, userData.role === 'admin');
          //localStorage.setItem('listingsFetched', 'true');
          
        } else {
          setUser(null);
          setIsAdmin(false);
        }
      } else {
        // User is logged out
        setUser(null);
        setIsAdmin(false);
        localStorage.removeItem('listingsFetched');
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  


  const fetchListings = async (userId, isAdmin) => {
    console.log(isAdmin)
    console.log(userId);
    try {
      setIsLoading(true);
      const response = await api.get('/api/listings', {
      // const response = await axios.get('http://localhost:3000/api/listings', {
        params: {
          userId,
        },
      });
      setListings(response.data || []);
      console.log(response.data)
    } catch (error) {
      console.error('Error fetching listings:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleCloseSidebar = () => {
    setIsSidebarOpen(false);
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <UserContext.Provider value={{ user, setUser, isSidebarOpen, handleToggleSidebar, handleCloseSidebar, isAdmin, listings, setListings }}>
      <Router>
        <Routes>
          <Route path="/login" element={user ? <Navigate to="/" replace /> : <SignIn />} />
          {/* <Route path="/" element={<PrivateRoute element={Dashboard} />} /> */}
          <Route path="/" element={<PrivateRoute element={ListingsPage} />} />
          <Route path="/listings" element={<PrivateRoute element={ListingsPage} />} />
          <Route path="/settings" element={<PrivateRoute element={SettingsPage} isAdminOnly />} />
          <Route path="/property/:id" element={<PrivateRoute element={PropertyDetails} isAdminOnly />} />
          <Route path="/listings/:id" element={<DetailedPropertyPage />} />
          {/* <Route path="/listings/:id" element={<PrivateRoute element={DetailedPropertyPage} />} /> */}
          <Route path="/reservations/:id" element={<PrivateRoute element={DetailedReservationPage} />} />
          <Route path="/accountant" element={<PrivateRoute element={AccountantPage} isAdminOnly />} />
          <Route path="/allreservations" element={<PrivateRoute element={AllReservations} isAdminOnly />} />



          <Route path="*" element={<NotFoundPage />} />
         

        </Routes>
      </Router>
    </UserContext.Provider>
  );
};

export default App;